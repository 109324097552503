import clsx from 'clsx'
import React from 'react'

export const InfiniteScroller = (props: {
  children: any
  loading: boolean
  loadNext: () => void
  className?: string
}) => {
  const divRef = React.useRef<HTMLDivElement | null>(null)
  const { children, loading, loadNext } = props
  const loader = React.useRef(loadNext)

  React.useEffect(() => {
    loader.current = loadNext
  }, [loadNext])

  const handleScroll = React.useCallback(() => {
    const scrollable = divRef.current
    if (!scrollable) {
      return
    }
    const { scrollTop, clientHeight, scrollHeight } = scrollable

    const scrollBottom = scrollHeight - (scrollTop + clientHeight)
    // const scrollDifference = Math.floor(window.innerHeight + window.scrollY)
    // const scrollEnded = scrollHeight == scrollDifference

    if (scrollBottom < 50 && !loading) {
      loader.current()
    }
  }, [divRef, loader])

  React.useEffect(() => {
    const scrollable = divRef.current
    if (!scrollable) {
      return
    }
    if (typeof window !== 'undefined') {
      scrollable.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollable) {
        scrollable.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div ref={divRef} className={clsx('h-fit max-h-[70svh] overflow-y-auto', props.className)}>
      {children}
    </div>
  )
}
