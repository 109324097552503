// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/history-item/provider.ts"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import {
  CustomerHistoryItemsQuery,
  HistoryEntryType,
  HistoryItemListOptions,
  HistoryItemsQuery,
  OrderHistoryItemsQuery,
} from '~admin/generated/graphql'
import { QueryOptions, sdk } from '~admin/graphqlWrapper'

export const orderList = (
  orderIds: string[],
  types?: HistoryEntryType[],
  options?: HistoryItemListOptions,
  queryOptions?: QueryOptions,
): Promise<OrderHistoryItemsQuery> => {
  return sdk.orderHistoryItems(
    types ? { orderIds, types, options } : { orderIds, options },
    queryOptions,
  )
}
export const customerList = (
  customerIds: string[],
  types?: HistoryEntryType[],
  options?: HistoryItemListOptions,
  queryOptions?: QueryOptions,
): Promise<CustomerHistoryItemsQuery> => {
  return sdk.customerHistoryItems(
    types ? { customerIds, types, options } : { customerIds, options },
    queryOptions,
  )
}
export const list = (
  types?: HistoryEntryType[],
  options?: HistoryItemListOptions,
  queryOptions?: QueryOptions,
): Promise<HistoryItemsQuery> => {
  return sdk.historyItems(types ? { types, options } : { options }, queryOptions)
}
